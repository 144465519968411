<template>
  <div class="sign-wrapper">
    <div class="sign">
      <div class="sign-up">
        <router-link class="sign-up__link" :to="{ name: 'sign-up' }">
          Don’t have an account? Sign up.
        </router-link>
        <a @click.prevent="signinGoogle" class="sign-up__google" href="#">
          <span class="sign-up__google-icon">
            <img src="@/assets/images/google.svg" alt="google">
          </span>
          Login with Google
        </a>
      </div>
      <h1 class="sign__title">Sign in</h1>

      <form class="sign__form" @submit.prevent="onSubmit">

        <input v-model="email" class="sign__input" type="text" placeholder="ID / Email"/>

        <div v-if="$v.email.$error">
          <small v-if="!$v.email.required" class="sign__form-error">
            Field is required
          </small>

          <small v-if="$v.email.$invalid" class="sign__form-error">
            Invalid email
          </small>
        </div>

        <input v-model="password" class="sign__input" type="password" placeholder="Password"/>

        <div v-if="$v.password.$error">
          <small v-if="!$v.password.required" class="sign__form-error">
            Password required
          </small>
        </div>
        <small v-if="fetchError" class="sign__form-error">
          {{ fetchError }}
        </small>

        <div class="sign__submit-wrapper">
          <button class="sign__submit" type="submit" :disabled="isFetching">Sign in</button>
          <div class="sign__captcha"></div>
        </div>

      </form>
      <router-link class="sign__forgot-password" :to="{ name: 'forgot-password' }">
        I forgot password
      </router-link>
    </div>

  </div>

</template>

<script>
  import {SIGNIN_REQUEST, MODULE_NAME, GOOGLE_SIGNIN} from "@/store/modules/auth";
  import { required, email } from 'vuelidate/lib/validators'

  export default {
    name: 'SignIn',
    data() {
      return {
        email: '',
        password: '',
      }
    },
    validations: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    },
    computed: {
      authState() {
        return this.$store.state[MODULE_NAME].login
      },
      isFetching() {
        return this.authState.loading
      },
      fetchSuccess() {
        return this.authState.data
      },
      fetchError() {
        return this.authState.error
      }
    },
    methods: {
      async onSubmit() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }

        await this.$store.dispatch(SIGNIN_REQUEST, {
          email: this.email,
          password: this.password,
        })

      },
      async signinGoogle() {
        // const response = await this.$store.getters.apiService.getGoogleAuth()
        // console.log('res', response)
        const authUrl = this.$store.getters.apiService.getGoogleAuthURL()
        // this.loginWindow = window.open(authUrl, '',
        //   'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no');
        const width = 600;
        const height = 600;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);

        window.location = authUrl
//         this.loginWindow = window.open(authUrl, '',
//           `toolbar=no, location=no, directories=no, status=no, menubar=no,
// scrollbars=no, resizable=no, copyhistory=no, width=${width},
// height=${height}, top=${top}, left=${left}`
//         );
//         window.addEventListener('message', (event) => {
//           this.onLogin(event.data);
//         });
      },
      onLogin(msg) {
        if (!msg) {
          return
        }

        const token = msg.split('GOOGLE_AUTH_JWT:')[1]
        if (token) {
          console.log('onLogin', token);
          // localStorage.setItem('token', token);
          this.loginWindow.close();
          window.removeEventListener('message', this.onLogin);

          this.$store.dispatch(GOOGLE_SIGNIN, {token})
        }
      }
    }
  }
</script>
